@import './../../../../scss/theme-bootstrap';

.basic-grid-formatter {
  &--padding {
    padding: 0 10px;
    @include breakpoint($medium-up) {
      padding: 0 30px;
    }
    @include breakpoint($large-up) {
      padding: 0 60px;
    }
  }
}

.basic-grid {
  font-size: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.basic-grid__item {
  display: inline-flex;
  justify-content: center;
  vertical-align: top;
  width: 100%;
  font-size: 14px;
  overflow: hidden;
  @include breakpoint($small-only) {
    .basic-grid--small-cols-1 & {
      margin-bottom: 20px;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .basic-grid--small-cols-2 & {
      width: 50%;
      .basic-grid-formatter--gutters & {
        width: grid-item-width(2, 15px);
      }
    }
    .basic-grid--small-cols-3 & {
      width: 33.33333%;
      .basic-grid-formatter--gutters & {
        width: grid-item-width(3, 15px);
      }
    }
    .basic-grid--small-cols-4 & {
      width: 25%;
      .basic-grid-formatter--gutters & {
        width: grid-item-width(4, 10px);
      }
    }
  }
  @include breakpoint($medium-only) {
    .basic-grid--medium-cols-2 & {
      width: 50%;
      .basic-grid-formatter--gutters & {
        width: grid-item-width(2, 20px);
      }
    }
    .basic-grid--medium-cols-3 & {
      width: 33.33333%;
      .basic-grid-formatter--gutters & {
        width: grid-item-width(3, 20px);
      }
    }
    .basic-grid--medium-cols-4 & {
      width: 25%;
      .basic-grid-formatter--gutters & {
        width: grid-item-width(4, 20px);
      }
    }
  }
  @include breakpoint($large-up) {
    font-size: 16px;
    .basic-grid--large-cols-2 & {
      width: 50%;
      .basic-grid-formatter--gutters & {
        width: grid-item-width(2, 20px);
      }
    }
    .basic-grid--large-cols-3 & {
      width: 33.33333%;
      .basic-grid-formatter--gutters & {
        width: grid-item-width(3, 20px);
      }
    }
    .basic-grid--large-cols-4 & {
      width: 25%;
      .basic-grid-formatter--gutters & {
        width: grid-item-width(4, 20px);
      }
    }
  }
}

.basic-grid__header {
  padding: 0 20px;
  margin: 0 0 15px 0;
  @include breakpoint($medium-up) {
    padding: 0 60px;
    margin-bottom: 20px;
  }
}

.basic-grid__title,
.basic-grid__description {
  margin: 0;
  > p {
    margin: 0;
    line-height: inherit;
  }
}

.basic-grid__description {
  margin-top: 5px;
}

.basic-grid__footer {
  margin: 10px 0 0 0;
  padding: 0 10px;
  @include breakpoint($medium-up) {
    margin-top: 20px;
    padding: 0 35px;
  }
  .basic-grid__button {
    @include extended-link;
    &.button {
      border-width: 1px;
      color: $color-white;
      @include breakpoint($small-only) {
        width: 100%;
      }
      &.button--inverted {
        color: $color-black;
      }
      &.button--large {
        font-size: 19px;
      }
    }
  }
}

.max-override {
  .basic-grid__item {
    width: 100%;
  }
  .basic-grid__title {
    margin-top: -20px;
  }
}

.wave-key-max {
  max-width: 1080px;
}

.thickening-navigation {
  margin-top: -25px;
  .basic-tout__headline {
    margin-bottom: 0;
    margin-top: 17px;
    letter-spacing: 0.075em;
  }
  .anchor {
    display: block;
    position: relative;
    top: -120px;
    visibility: hidden;
  }
  .basic-tout {
    p {
      border-bottom: 3px solid transparent;
      display: inline-block;
      transition: 0.2s ease-in-out;
    }
    &:hover {
      p {
        border-bottom: 3px solid $color-black;
        display: inline-block;
        transition: 0.2s ease-in-out;
      }
    }
  }
}

.perks-overview {
  > .basic-grid {
    max-width: 750px;
    margin: 0 auto;
    .perk-container {
      margin-bottom: 30px;
      position: relative;
      padding-#{$ldirection}: 65px;
      .perk-icon {
        position: absolute;
        #{$ldirection}: 0;
        top: 0;
      }
    }
  }
}

.custom-tout-header {
  .basic-grid__header {
    background: $color-lighter-gray;
    margin-bottom: 0;
    padding: 15px 12px 12px;
    .header--small {
      letter-spacing: 0.05em;
      font-size: 20px;
    }
  }
  .new-icon {
    .hero-large__text--logo {
      img {
        width: 45px;
        margin-#{$rdirection}: 290px;
        margin-bottom: -12px;
      }
    }
  }
}

.pre-style {
  .hero-large__inner-vpos--bottom {
    bottom: 3%;
  }
}

.product-full {
  .product-grid__item {
    width: 50%;
    padding-top: 2%;
  }
}

.top-align {
  padding-top: 0;
  padding-bottom: 0;
  margin-top: -15px;
  margin-bottom: -10px;
}

.routine-full {
  .product-grid__item {
    width: 100%;
    padding-top: 7%;
  }
}

.collections-grid-pad {
  .product-brief {
    .product__image-badge {
      top: 8px;
    }
  }
}
